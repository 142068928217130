import React from "react";
import "./Antifraud.scss";
import { Link } from "gatsby";

import ArrowRightSvg from "../../images/icons/buttons/arrow-right.svg";

export const Antifraud = ({ messages }) => {
    return ( 
        <div className="Antifraud">
            <div className="container Antifraud-Container">
                <div className="Antifraud-Text">
                    {messages.question}
                </div>
                <div className="Antifraud-Link">
                    <Link 
                        className="Antifraud-LinkBtn" 
                        href="/financial-security"
                    >
                       {messages.link}
                        <img 
                            className="Antifraud-LinkBtn-Icon" 
                            src={ArrowRightSvg} 
                            alt="arrow-right" 
                        />
                    </Link>
                </div>
            </div>
        </div>
    );
};