import React from "react"

import Layout from "../layout/Layout";
import SEO from "../components/seo";
import SubmitApplicationBlock from "../blocks/static/SubmitApplicationBlock";
import PageHeaderBlock from "../blocks/PageHeaderBlock";
import InfoCardsBlock from "../blocks/InfoCardsBlock";

import enMessages from '../i18n/index/en.js'
import ruMessages from '../i18n/index/ru.js'
import { Antifraud } from "../components/Antifraud";

const messages = {
    en: enMessages,
    ru: ruMessages
}

const IndexPage = ({ pathContext: { locale }, data }) => {
    return (
        <Layout locale={locale}>
            <SEO
                title={messages[locale].seo.title}
                description={messages[locale].seo.description}
            />
            {locale === 'ru' && <Antifraud messages={messages[locale].antifraud} />}
            <PageHeaderBlock
                locale={locale}
                theme="index"
                title={messages[locale].pageHeaderBlock.title}
                text={messages[locale].pageHeaderBlock.text}
            />
            <InfoCardsBlock
                cards={messages[locale].infoCardsBlock.cards}
                cardsTitleColor="first"
                cardsTitle={messages[locale].infoCardsBlock.cardsTitle}
            />
            <InfoCardsBlock
                cards={messages[locale].infoCardsBlockWhyUs.cards}
                cardsLayout="row"
                cardsTitle={messages[locale].infoCardsBlockWhyUs.cardsTitle}
                cardsTitleColor="grey"
                config={{
                    animation: '2',
                    baseBorder: true
                }}
            />
            <SubmitApplicationBlock
                title={messages[locale].submitApplicationBlock.title}
                textDescription={messages[locale].submitApplicationBlock.textDescription}
                textSubmit={messages[locale].submitApplicationBlock.textSubmitBottom || messages[locale].submitApplicationBlock.textSubmit}
            />
        </Layout>
    );
}

export default IndexPage
